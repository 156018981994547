<template>
  <div class="mobile-container">
    <div class="page-bg img-cover">
      <img src="../../assets/images/login-bg.png" />
    </div>
    <div class="page-content">
      <div class="status-bar">
        <div class="left-btn img-cover" @click="$router.go(-1)">
          <img src="../../assets/images/back-icon-black.png" />
        </div>
        <div class="title-item">注册</div>
      </div><div class="page-title">
        <div class="title">Hello!</div>
        <div class="sub-title">欢迎来到{{ websiteInfo.SiteName }}</div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/username-icon.png" /></div>
          <div class="name">用户名/手机号</div>
        </div>
        <div class="input">
          <input v-model="from.UserName" type="text" placeholder="请输入您的手机号/用户名" />
        </div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/username-icon.png" /></div>
          <div class="name">手机号</div>
        </div>
        <div class="input">
          <input v-model="from.Phone" type="text" placeholder="请输入您的手机号" />
        </div>
      </div>
      <div v-if="showZcYzm" class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/code-icon.png" /></div>
          <div class="name">图形验证码</div>
        </div>
        <div class="input">
          <input v-model="from.CaptchaValue" type="text" placeholder="请输入验证码" />
          <div class="code-img img-cover">
            <img v-if="captchaPath" :src="captchaPath" @click="handleGetCaptcha" />
          </div>
        </div>
      </div>
      <div v-if="showZcYzm" class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/code-icon.png" /></div>
          <div class="name">短信验证码</div>
        </div>
        <div class="input">
          <input v-model="from.ZcYqm" type="text" placeholder="请输入短信验证码" />
          <div class="send-code" :class="time > 0 ? 'not' : ''" @click="getMsg">{{ time > 0 ? `${time}s` : '发送验证码' }}</div>
        </div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/pwd-icon.png" /></div>
          <div class="name">密码</div>
        </div>
        <div class="input">
          <input v-model="from.Pwd" type="password" placeholder="请输入您的密码" />
        </div>
      </div>
      <div class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/pwd-icon.png" /></div>
          <div class="name">确认密码</div>
        </div>
        <div class="input">
          <input v-model="from.SurePwd" type="password" placeholder="请确认密码" />
        </div>
      </div>
      <div v-if="showYqm" class="input-item">
        <div class="title-item">
          <div class="icon img-cover"><img src="../../assets/images/yqm-icon.png" /></div>
          <div class="name">邀请码</div>
        </div>
        <div class="input">
          <input v-model="from.Yqm" type="text" placeholder="请输入邀请码" />
        </div>
      </div>
      <div class="btn-item public-btn" @click="submit">
        <van-loading v-if="submiting" color="#ffffff" />
        <span v-else>确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Register",
  data() {
    return {
      submiting: false,
      showYqm: false,
      showZcYzm: false,
      captchaPath: '',
      timer: null,
      time: 0,
      from: {
        UserName: '',
        Phone: '',
        CaptchaValue: '',
        ZcYqm: '',
        Pwd: '',
        SurePwd: '',
        Yqm: '',
      }
    }
  },
  computed: {
    ...mapState(['websiteInfo'])
  },
  mounted () {
    this.handleGetSetting()
  },
  methods: {
    async handleGetSetting() {
      var res = await this.$API.getSets.post({
        Key: 'is_yqm,is_zc_yzm'
      })
      this.showYqm = res.is_yqm == 1
      this.showZcYzm = res.is_zc_yzm == 1
      if (res.is_zc_yzm == 1) {
        this.handleGetCaptcha()
      }
    },
    handleGetCaptcha() {
      this.captchaPath = this.$HTTP.baseURL + '/common/captcha?v=' + (new Date().getTime())
    },
    async getMsg() {
      const { from, time, timer } = this
      if (time > 0 || timer != null) {
        return
      }
      if (!from.Phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!from.CaptchaValue) {
        this.$toast('请输入图形验证码')
        return
      }
      var res = await this.$API.sendMsg.post({
        Phone: from.Phone,
        CaptchaValue: from.CaptchaValue,
      })
      if (res.error == 0) {
        this.setTimer()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        this.handleGetCaptcha()
      }
    },
    setTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      let t = 60
      this.time = t
      this.timer = setInterval(() => {
        t--
        this.time = t
        if (t <= 0) {
          clearInterval(this.timer)
        }
      }, 1000);
    },
    async submit() {
      const { from, showYqm, showZcYzm } = this
      if (!from.UserName) {
        this.$toast('请输入您的手机号/用户名')
        return
      }
      if (!from.Phone) {
        this.$toast('请输入您的手机号')
        return
      }
      if (showZcYzm && !from.ZcYqm) {
        this.$toast('请输入短信验证码')
        return
      }
      if (!from.Pwd) {
        this.$toast('请输入您的密码')
        return
      }
      if (!from.SurePwd) {
        this.$toast('请输入确认密码')
        return
      }
      if (from.Pwd != from.SurePwd) {
        this.$toast('两次输入密码不一致')
        return
      }
      if (!showYqm || !from.Yqm) {
        delete from.Yqm
      }
      this.submiting = true
      var res = await this.$API.register.post(from)
      this.submiting = false
      if (res.error == 0) {
        this.$dialog.alert({
          type: 'success',
          title: '提示',
          message: res.info,
          confirmButtonText: '去登录'
        }).then(() => {
          this.$router.push({path: '/login'})
        })
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.page-bg {
  width: var(--max-width);
  height: 12rem;
  position: fixed;
  top: 0;
}
.page-content {
  position: relative;
  padding: 1.86rem .72rem 1.3rem;
  z-index: 1;
  .status-bar {
    position: fixed;
    top: 0;
    left: 50%;
    margin-left: var(---max-width-50);
    width: var(--max-width);
    height: .88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .32rem;
    font-weight: 500;
    color: #030304;
    .left-btn {
      cursor: pointer;
      position: absolute;
      left: .24rem;
      width: .6rem;
      height: .6rem;
    }
  }
  .page-title {
    color: #151d2b;
    font-weight: bold;
    margin-bottom: 1rem;
    .title {
      line-height: .74rem;
      font-size: .52rem;
    }
    .sub-title {
      line-height: .48rem;
      font-size: .32rem;
    }
  }
  .input-item {
    margin-bottom: .24rem;
    border-bottom: .02rem solid #f7f7f7;
    .title-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon {
        width: .32rem;
        height: .32rem;
        margin-right: .12rem;
      }
      .name {
        line-height: .42rem;
        font-size: .3rem;
        color: #151d2b;
        font-weight: 500;
      }
    }
    .input {
      height: .86rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        flex: 1;
        height: .4rem;
      }
      img {
        cursor: pointer;
        width: 2rem;
        height: .6rem;
      }
      .code-img {
        cursor: pointer;
        width: 2rem;
        height: .6rem;
        margin-left: .24rem;
      }
      .send-code {
        cursor: pointer;
        margin-left: .24rem;
        font-size: .28rem;
        color: #1399ff;
        &.not {
          color: #999;
        }
      }
    }
  }
  .btn-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .12rem;
    margin-top: .48rem;
    height: .88rem;
    font-size: .28rem;
    font-weight: bold;
    color: #fff;
  }
}
</style>
